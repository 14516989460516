import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../images/logo.svg";
import "./Header.css";

function Header() {
  const [showLinks, setShowLinks] = useState(false);

  const toggleLinks = () => {
    setShowLinks(!showLinks);
    const links = document.getElementById("links");
    if (links.style.display === "block") {
      links.style.display = "none";
    } else {
      links.style.display = "block";
    }
  };

  const hideLinks = () => {
    setShowLinks(false);
    const links = document.getElementById("links");
    if (links.style.display === "block") {
      links.style.display = "none";
    }
  };

  return (
    <div className="Header">
      <header>
        <div className="header-container">
          <div className="header-left">
            <img src={logo} alt="WWP Logo" />
            <h1>homeskil</h1>
          </div>
          <nav className="header-right">
            <ul className="links" id="links">
              <li>
                <a href="services" onClick={hideLinks}>
                  Services
                </a>
              </li>
              <li>
                <a href="#about" onClick={hideLinks}>
                  About Us
                </a>
              </li>
              <li>
                <a href="#contact" onClick={hideLinks}>
                  Contact
                </a>
              </li>
            </ul>
            <button className="hamburger" onClick={toggleLinks}>
              {showLinks ? <FaTimes /> : <FaBars />}
            </button>
          </nav>
        </div>
      </header>
    </div>
  );
}
export default Header;
