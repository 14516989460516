import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <footer>
      <p>
        &copy; 2023 World Wide Productions Pty Ltd - t/a homeskil. ABN: 84 068 751 771
      </p>
    </footer>
  );
}

export default Footer;
