import React, { useRef, useEffect, useState, useCallback } from "react";
import "./Projects.css";
import projectsData from "../data/projectsData.json";

const Projects = () => {
  const [imageInView, setImageInView] = useState([]);
	const fromLeft = "-200%";
	const fromRight ="200%";

  const imageRefs = useRef([]);

  const handleScroll = useCallback(() => {
    imageRefs.current.forEach((imageRef, index) => {
      const imageRect = imageRef.getBoundingClientRect();
      const isInView =
        imageRect.top >= 0 && imageRect.bottom <= window.innerHeight;

      if (isInView && !imageRef.classList.contains("in-view")) {
        imageRef.classList.add("in-view");
        setImageInView((prevState) => {
          const newState = [...prevState];
          newState[index] = true;
          return newState;
        });

      } else if (!isInView && imageRef.classList.contains("in-view")) {
        if (imageInView[index]) {
          imageRef.classList.remove("in-view");
        }
      }
    });
  }, [imageInView]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <div className="project-section">
      <h1 className="project-section-title">Highlighted Projects</h1>
      {projectsData.map((project, index) => (
        <div
          className="project"
          key={index}
          style={{ backgroundColor: project.backgroundColor }}
        >
          <div
            className={`project-image ${imageInView[index] ? "visible" : ""}`}
            style={{
							backgroundImage: `url(${((project.imageSrc))})`,
              transform: `translateX(${
                imageInView[index] ? 0 : `${fromLeft}`
              })`,
            }}
            ref={(el) => (imageRefs.current[index] = el)}
          ></div>
          <div
            className={`project-details ${
              imageInView[index] ? "visible" : ""
            }`}
            style={{
              transform: `translateX(${imageInView[index] ? 0 : `${fromRight}`})`,
            }}
          >
            <h3>{project.title}</h3>
            <p>{project.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Projects;
