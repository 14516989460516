import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';

const injectGA = () => {
  if (typeof window == 'undefined') {
    return;
  }	
  window.dataLayer = window.dataLayer || [];

  function gtag() {
    window.dataLayer.push(arguments);
  }
	
  gtag('js', new Date());

  gtag('config', 'G-RBTJKXLL20');
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
		{/* Google tag (gtag.js) --> */}
		<script async src="https://www.googletagmanager.com/gtag/js?id=G-RBTJKXLL20"></script>
		<script>{injectGA()}</script>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
