import React, {Fragment} from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import ContactUs from "./components/ContactUs";
import Services from "./components/Services";
import Projects from "./components/Projects";
import AboutUs from "./components/AboutUs";
import Footer from "./components/Footer";

import "./App.css";

function App() {
  return (
    <Fragment>
      <div id="container">
      <Header />
        <main>
          <section id="hero">
            <Hero />
          </section>
          <section id="services">
            <Services />
          </section>
          <section id="projects">
            <Projects />
          </section>
          <section id="about">
            <AboutUs />
          </section>
          <section id="contact">
            <ContactUs />
          </section>
        </main>
        <Footer />
      </div>
    </Fragment>
  );
}

export default App;
