import React, { Fragment } from "react";
import servicesData from "../data/servicesData.json";
import "./Services.css";

function Services() {
  return (
    <Fragment>
      <div className="sectionTitle">
        <h2>Services</h2>
      </div>
      <div className="tileSection">
        {servicesData.map((service, index) => (
          <div className="tile" key={index}>
            <h3>{service.title}</h3>
            
              <ul className="list">
                {service.points.map((point, index) => (
                  <li className="list-item" key={index}>{point}</li>
                ))}
              </ul>
            
          </div>
        ))}
      </div>
    </Fragment>
  );
}

export default Services;
