import React from "react";
import "./AboutUs.css";

function AboutUs() {
  return (
    <div className="aboutus">
      <h1>About Us</h1>
      <p>
        homeskil offers expert electronic design consultancy services. Our
        experienced team and partners specialises in designing circuit boards, PCB layout and
        assembly, building prototypes, embedded programming, 3D printing, and
        more.  </p>
				<p>
				Our mission is to provide innovative and customised electronic
        design solutions to help you accomplish your product needs.  With a focus on quality, innovation, and
        attention to detail, we are committed to delivering exceptional results
        that exceed your expectations. Contact us today to learn more about how
        we can help you with your electronic design needs.
      </p>
    </div>
  );
}

export default AboutUs;
