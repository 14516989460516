import React from "react";
import "./Hero.css";

function Hero() {
	return (
		<section id="hero">
			<div className="hero-content">
				<h2>Welcome to Homeskil</h2>
				<p >
					From concept to production, we bring your electronics ideas to life.
					Our expert team designs and builds electronic circuits, programs
					microcontrollers, assembles PCBs, 3D prints enclosures, and rigorously
					tests your products to ensure they meet the highest standards of
					quality and performance.
				</p>
				<a href="#services" className="button">
					Learn More
				</a>
			</div>
		</section>
	);
}

export default Hero;